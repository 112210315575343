import { MedicalSurveillanceEvaluationFlag, RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { SeverityQuestion } from './SeverityQuestion';
import { YesNoQuestion } from './YesNoQuestion';

export function SobBlock({
  gcdmseFlags,
  responses,
  rmeFlags,
}: {
  gcdmseFlags: MedicalSurveillanceEvaluationFlag[];
  responses: HealthHistoryStageResponses;
  rmeFlags: RespiratorMedicalEvaluationFlag[];
}) {
  return (
    <QuestionSection highlight={responses.sob === YesNo.YES || responses.sobWalkingFastOrIncline === YesNo.YES || responses.sobWalkingWithOthers === YesNo.YES || responses.sobWalkingOwnPaceLevel === YesNo.YES || responses.sobWashingOrDressing === YesNo.YES || responses.sobInterferingWithWork === YesNo.YES} title="Shortness of Breath">
      <YesNoQuestion
        response={responses.sob}
        text="Do you ever get short of breath?"
      />
      <YesNoQuestion
        highlight={responses.sobWalkingFastOrIncline === YesNo.YES && rmeFlags.includes(RespiratorMedicalEvaluationFlag.MODERATE_DYSPNEA)}
        response={responses.sobWalkingFastOrIncline}
        text="Do you get short of breath when walking fast on level ground or walking up a slight hill or incline?"
      />
      <YesNoQuestion
        highlight={responses.sobWalkingWithOthers === YesNo.YES && rmeFlags.includes(RespiratorMedicalEvaluationFlag.MODERATE_DYSPNEA)}
        response={responses.sobWalkingWithOthers}
        text="Do you get short of breath when walking with other people at an ordinary pace on level ground?"
      />
      <YesNoQuestion
        highlight={responses.sobWalkingOwnPaceLevel === YesNo.YES && rmeFlags.includes(RespiratorMedicalEvaluationFlag.MODERATE_DYSPNEA)}
        response={responses.sobWalkingOwnPaceLevel}
        text="Do you get short of breath when walking at your own pace on level ground?"
      />
      <YesNoQuestion
        highlight={responses.sobWashingOrDressing === YesNo.YES && rmeFlags.includes(RespiratorMedicalEvaluationFlag.MODERATE_DYSPNEA)}
        response={responses.sobWashingOrDressing}
        text="Do you get short of breath when showering or dressing yourself?"
      />
      <YesNoQuestion
        highlight={responses.sobInterferingWithWork === YesNo.YES && rmeFlags.includes(RespiratorMedicalEvaluationFlag.MODERATE_DYSPNEA)}
        response={responses.sobInterferingWithWork}
        text="Do you sometimes get so short of breath that it's hard to do your job?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.UNSTABLE_DYSPNEA)}
        response={responses.sobWorseLastYear}
        text="Has your shortness of breath gotten worse in the last year?"
      />
      <SeverityQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.SEVERE_DYSPNEA)}
        response={responses.sobSeverity}
        text="How bad do you and your doctor think your shortness of breath is?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.HX_DYSPNEA_ISSUES_IN_RESPIRATOR)}
        response={responses.sobPriorRespiratorUseNotOk}
        text="Has your shortness of breath ever made it difficult for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_DYSPNEA_ISSUES_IN_RESPIRATOR)}
        response={responses.sobFutureRespiratorUseExpectedNotOk}
        text="Do you think your shortness of breath will make it hard for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_DYSPNEA)}
        response={responses.sobWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your shortness of breath?"
      />
      <YesNoQuestion
        response={responses.sobMedications}
        text="Do you take any rescue medicines like albuterol when you have symptoms?"
      />
      {(responses['5fb58b44-53fb-4db7-9a39-571ee420f883'] !== YesNo.YES
          && responses.asthmaMedications !== YesNo.YES
          && (responses['06249dc2-9b9b-4e4f-a012-af25cfa05ae7'] === undefined  // Hack to detect GCDMS
            || responses.chronicBronchitisMedications !== YesNo.YES
          )) && (
        <YesNoQuestion
          highlight={
            gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.POORLY_CONTROLLED_ASTHMA)
            || rmeFlags.includes(RespiratorMedicalEvaluationFlag.POORLY_CONTROLLED_ASTHMA)
          }
          response={responses.rescueInhalerUseAtLeast3PerWeek}
          text="Do you use your rescue inhaler 3 or more times per week?"
        />
      )}
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.DYSPNEA_RESCUE_MEDS_INACCESSIBLE_AT_WORK)}
        response={responses.sobMedicationsAccessibleAtWork}
        text="When you're at work, can you get to your medicines easily and take them whenever you need to?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_DYSPNEA)}
        response={responses.sobHospitalStayLast2Years}
        text="In the last two (2) years, have you had to stay in the hospital because of your shortness of breath?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.HX_DYSPNEA_IMPACTING_WORK)}
        response={responses.sobImpactedWorkLast2Years}
        text="In the last two (2) years, have you had to leave work or stop doing a task early because of your shortness of breath?"
      />
    </QuestionSection>
  );
}
