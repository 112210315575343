import { MedicalSurveillanceEvaluationFlag, RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { AllergySymptom, YesNo, YesNoUnknown } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { DescriptionQuestion } from './DescriptionQuestion';
import { EnumQuestion } from './EnumQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function AllergiesBlock({
  gcdmseFlags,
  responses,
  rmeFlags,
}: {
  gcdmseFlags: MedicalSurveillanceEvaluationFlag[];
  responses: HealthHistoryStageResponses;
  rmeFlags: RespiratorMedicalEvaluationFlag[];
}) {
  return (
    <QuestionSection
      highlight={
        responses['06249dc2-9b9b-4e4f-a012-af25cfa05ae7'] === YesNo.YES
        || responses.allergies === YesNo.YES
        || responses.allergiesAnaphylaxis === YesNo.YES
        || responses.allergiesMedications === YesNo.YES
        || (
          responses['ba4b4735-3b53-42e6-b15a-6b004bdef9eb'] !== undefined
          && !responses['ba4b4735-3b53-42e6-b15a-6b004bdef9eb'].includes(AllergySymptom.NONE)
        )
      }
      title="Allergies"
    >
      {responses['06249dc2-9b9b-4e4f-a012-af25cfa05ae7'] !== undefined && (
        <YesNoQuestion
          response={responses['06249dc2-9b9b-4e4f-a012-af25cfa05ae7']}
          text="Have you ever had allergies?"
        />
      )}
      {responses['cbfbe782-2f39-4214-b1cf-1c7af56e28b8'] !== undefined && (
        <YesNoQuestion
          response={responses['cbfbe782-2f39-4214-b1cf-1c7af56e28b8']}
          text="Do you still have any allergies?"
        />
      )}
      <YesNoQuestion
        response={responses.allergies}
        text="Have you ever had an allergic reaction that interfered with your breathing?"
      />
      <YesNoQuestion
        highlight={gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.HX_ANAPHYLAXIS)}
        response={responses.allergiesAnaphylaxis}
        text="Have you ever had a really bad allergic reaction called anaphylaxis, or gone to the hospital because you had trouble breathing from an allergy?"
      />
      <YesNoQuestion
        highlight={responses.allergiesMedications === YesNo.NO && responses.allergiesPrescribedEpipen === YesNo.NO && (responses.allergiesHadAtWork === YesNo.NO || responses.allergiesTriggersAtWork === YesNoUnknown.UNKNOWN) && rmeFlags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_UNTREATABLE_ALLERGIES_AT_WORK)}
        response={responses.allergiesMedications}
        text="Do you take any medicines for your allergies?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.ADVERSE_EFFECTS_OF_ALLERGY_MEDS)}
        response={responses.allergiesMedicationsAdverseEffects}
        text="Do your allergy medicines make you feel sleepy, mixed up, tired, or not able to think clearly?"
      />
      <YesNoQuestion
        highlight={responses.allergiesMedications === YesNo.NO && responses.allergiesPrescribedEpipen === YesNo.NO && (responses.allergiesHadAtWork === YesNo.NO || responses.allergiesTriggersAtWork === YesNoUnknown.UNKNOWN) && rmeFlags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_UNTREATABLE_ALLERGIES_AT_WORK)}
        response={responses.allergiesPrescribedEpipen}
        text="Have you been given a prescription for an EpiPen®?"
      />
      {responses['5fb58b44-53fb-4db7-9a39-571ee420f883'] !== undefined && (
        <YesNoQuestion
          response={responses['5fb58b44-53fb-4db7-9a39-571ee420f883']}
          text="Do you use a rescue inhaler (such as albuterol)?"
        />
      )}
      {responses['5fb58b44-53fb-4db7-9a39-571ee420f883'] === YesNo.YES && (
        <YesNoQuestion
          highlight={
            gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.POORLY_CONTROLLED_ASTHMA)
            || rmeFlags.includes(RespiratorMedicalEvaluationFlag.POORLY_CONTROLLED_ASTHMA)
          }
          response={responses.rescueInhalerUseAtLeast3PerWeek}
          text="Do you use your rescue inhaler 3 or more times per week?"
        />
      )}
      {responses['270f371b-cbbd-41cf-8fe1-9cefafd782ad'] !== undefined && (
        <YesNoQuestion
          highlight={gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.ALLERGY_MEDS_NEEDED_MORE_AT_WORK)}
          response={responses['270f371b-cbbd-41cf-8fe1-9cefafd782ad']}
          text="Do you need to use your inhaler or allergy medicines more when you are at work?"
        />
      )}
      {responses['4295e5c3-a3ff-479c-bd68-bedaa4be5a84'] !== undefined && (
        <YesNoQuestion
          response={responses['4295e5c3-a3ff-479c-bd68-bedaa4be5a84']}
          text="Do you regularly get allergy shots?"
        />
      )}
      {responses['2ca0b5f9-6e55-48eb-a00e-7d8676a7ba31'] !== undefined && (
        <YesNoQuestion
          highlight={responses['2ca0b5f9-6e55-48eb-a00e-7d8676a7ba31'] === YesNo.YES}
          response={responses['2ca0b5f9-6e55-48eb-a00e-7d8676a7ba31']}
          text="Do you get shots for your allergies because you are allergic to something at your job?"
        />
      )}
      <YesNoQuestion
        response={responses.allergiesKnowsTriggers}
        text="Do you know what you are allergic to?"
      />
      {responses['db6ea31d-e90f-4bb0-a08d-0a8b06970483'] !== undefined && (
        <YesNoQuestion
          response={responses['db6ea31d-e90f-4bb0-a08d-0a8b06970483']}
          text="Are your allergies only to foods, medications, or common things in the environment?"
        />
      )}
      <YesNoQuestion
        highlight={
          (gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.EXPOSURE_TO_ALLERGENS_AT_WORK) && responses.allergiesTriggersAtWork === YesNoUnknown.YES)
          || rmeFlags.includes(RespiratorMedicalEvaluationFlag.EXPOSURE_TO_ALLERGENS_AT_WORK)
          || (responses.allergiesMedications === YesNo.NO && responses.allergiesPrescribedEpipen === YesNo.NO && (responses.allergiesHadAtWork === YesNo.NO || responses.allergiesTriggersAtWork === YesNoUnknown.UNKNOWN) && rmeFlags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_UNTREATABLE_ALLERGIES_AT_WORK))
        }
        response={responses.allergiesTriggersAtWork}
        text="In your current job, will you work around the cause of your allergic reaction(s)?"
      />
      {responses['f599c3ef-346b-4bcf-904d-c1883391d0bb'] !== undefined && (
        <YesNoQuestion
          highlight={responses['f599c3ef-346b-4bcf-904d-c1883391d0bb'] === YesNo.NO}
          response={responses['f599c3ef-346b-4bcf-904d-c1883391d0bb']}
          text="Can you avoid the things that trigger your allergies when you are at your job?"
        />
      )}
      {responses['e454ea87-5079-4111-8669-c5800986c72c'] !== undefined && (
        <YesNoQuestion
          response={responses['e454ea87-5079-4111-8669-c5800986c72c']}
          text="Have you ever had skin, blood, or patch testing for allergies?"
        />
      )}
      {responses['caf3a724-7957-4b9e-8c1e-b3072962b282'] !== undefined && (
        <EnumQuestion
          choices={{
            [YesNoUnknown.YES]: 'ALLERGENS AT WORKPLACE',
            [YesNoUnknown.NO]: 'ALLERGENS NOT AT WORKPLACE',
            [YesNoUnknown.UNKNOWN]: 'OTHER / UNKNOWN',
          }}
          highlight={gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.EXPOSURE_TO_ALLERGENS_AT_WORK) && responses['caf3a724-7957-4b9e-8c1e-b3072962b282'] === YesNoUnknown.YES}
          response={responses['caf3a724-7957-4b9e-8c1e-b3072962b282']}
          text="What was the result of your allergy testing?"
        />
      )}
      <YesNoQuestion
        highlight={
          rmeFlags.includes(RespiratorMedicalEvaluationFlag.HX_ALLERGIC_REACTION_AT_WORK)
          || gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.HX_ALLERGIC_REACTION_AT_WORK)
          || (responses.allergiesMedications === YesNo.NO && responses.allergiesPrescribedEpipen === YesNo.NO && (responses.allergiesHadAtWork === YesNo.NO || responses.allergiesTriggersAtWork === YesNoUnknown.UNKNOWN) && rmeFlags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_UNTREATABLE_ALLERGIES_AT_WORK))
        }
        response={responses.allergiesHadAtWork}
        text="Have you ever had an allergic reaction at work?"
      />
      <YesNoQuestion
        highlight={(responses.allergiesAtWorkEasilyTreatable === YesNoUnknown.NO || responses.allergiesAtWorkEasilyTreatable === YesNoUnknown.UNKNOWN) && rmeFlags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_UNTREATABLE_ALLERGIES_AT_WORK)}
        response={responses.allergiesAtWorkEasilyTreatable}
        text={`If you had an allergic reaction at work, could you easily get away and use your medicine${responses.allergiesPrescribedEpipen === YesNo.YES ? ', like your EpiPen®' : ''}?`}
      />
      {responses['ba4b4735-3b53-42e6-b15a-6b004bdef9eb'] !== undefined && (
        <>
          <div>Have you had any of the following allergy symptoms in the past month?</div>
          <ul className="list-disc ml-6">
            {responses['ba4b4735-3b53-42e6-b15a-6b004bdef9eb'].includes(AllergySymptom.OCULAR) && (
              <li>Watery, red, or itchy eyes</li>
            )}
            {responses['ba4b4735-3b53-42e6-b15a-6b004bdef9eb'].includes(AllergySymptom.NASAL) && (
              <li>Runny nose or sneezing</li>
            )}
            {responses['ba4b4735-3b53-42e6-b15a-6b004bdef9eb'].includes(AllergySymptom.COUGH) && (
              <li>Coughing fits</li>
            )}
            {responses['ba4b4735-3b53-42e6-b15a-6b004bdef9eb'].includes(AllergySymptom.DYSPNEA) && (
              <li>Difficulty breathing or chest tightness</li>
            )}
            {responses['ba4b4735-3b53-42e6-b15a-6b004bdef9eb'].includes(AllergySymptom.WHEEZE) && (
              <li>Wheezing</li>
            )}
            {responses['ba4b4735-3b53-42e6-b15a-6b004bdef9eb'].includes(AllergySymptom.NONE) && (
              <li>None</li>
            )}
          </ul>
        </>
      )}
      {responses['8c74a4f7-9379-4dcf-a288-4c8ef64a4226'] !== undefined && (
        <YesNoQuestion
          highlight={gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.CURRENT_ILLNESS)}
          response={responses['8c74a4f7-9379-4dcf-a288-4c8ef64a4226']}
          text="Are your allergy symptoms due to a cold, flu, or COVID-19?"
        />
      )}
      {responses['9153b85a-c691-4af9-817f-414205a612fa'] !== undefined && (
        <YesNoQuestion
          response={responses['9153b85a-c691-4af9-817f-414205a612fa']}
          text="When you don't have a cold, flu, or COVID-19, do you still have those symptoms?"
        />
      )}
      {responses['2d30e90c-1391-43ce-832a-42ab884cef27'] !== undefined && (
        <div>What year did your allergy symptoms start? <span className="font-bold ml-2">{responses['2d30e90c-1391-43ce-832a-42ab884cef27']}</span></div>
      )}
      {responses['b69e4944-8be7-48cf-b44f-a8311c1e2099'] !== undefined && (
        <YesNoQuestion
          highlight={responses['b69e4944-8be7-48cf-b44f-a8311c1e2099'] === YesNo.NO}
          response={responses['b69e4944-8be7-48cf-b44f-a8311c1e2099']}
          text="Did your allergy symptoms start before you began your current job?"
        />
      )}
      {responses['d0f608e3-2a67-4b43-aa11-f35c75298698'] !== undefined && (
        <YesNoQuestion
          highlight={responses['d0f608e3-2a67-4b43-aa11-f35c75298698'] === YesNo.YES}
          response={responses['d0f608e3-2a67-4b43-aa11-f35c75298698']}
          text="Do your allergy symptoms get worse when you're at work or during the work week?"
        />
      )}
      {responses['0243fead-8c59-435e-9fc8-2b8bf15ad548'] !== undefined && (
        <YesNoQuestion
          highlight={responses['0243fead-8c59-435e-9fc8-2b8bf15ad548'] === YesNo.YES}
          response={responses['0243fead-8c59-435e-9fc8-2b8bf15ad548']}
          text="Do your allergy symptoms get better when you are off work or go on vacation?"
        />
      )}
      {responses['68c52e1d-72ca-40be-a35d-8c4dc4bea0bd'] !== undefined && (
        <YesNoQuestion
          response={responses['68c52e1d-72ca-40be-a35d-8c4dc4bea0bd']}
          text="Do you just have allergy symptoms during certain seasons?"
        />
      )}
      {responses['8e64f0ed-9d53-48ed-b9c9-35e95202184c'] !== undefined && (
        <YesNoQuestion
          highlight={responses['8e64f0ed-9d53-48ed-b9c9-35e95202184c'] === YesNo.YES}
          response={responses['8e64f0ed-9d53-48ed-b9c9-35e95202184c']}
          text="Have you ever had to change jobs because of your allergy symptoms?"
        />
      )}
      {responses['f16ccc9d-36a3-4b9f-9364-d269e2f790ac'] !== undefined && (
        <YesNoQuestion
          highlight={responses['f16ccc9d-36a3-4b9f-9364-d269e2f790ac'] === YesNo.YES}
          response={responses['f16ccc9d-36a3-4b9f-9364-d269e2f790ac']}
          text="Are there specific tasks at your job that make your allergy symptoms worse?"
        />
      )}
      {responses['2e978d65-fabd-4584-abfa-eb4294cd02f2'] !== undefined && (
        <>
          <div>Which of your job tasks make your allergy symptoms worse?</div>
          <ul className="list-disc ml-6">
            {responses['2e978d65-fabd-4584-abfa-eb4294cd02f2'].map((task, i) => (
              <li key={i}>{task}</li>
            ))}
          </ul>
        </>
      )}
      {responses['73cf1348-082f-40ee-8b7a-2acac4614458'] !== undefined && (
        <YesNoQuestion
          highlight={responses['73cf1348-082f-40ee-8b7a-2acac4614458'] === YesNo.YES}
          response={responses['73cf1348-082f-40ee-8b7a-2acac4614458']}
          text="Are there certain chemicals or materials you work with that make your allergy symptoms worse?"
        />
      )}
      {responses['a6780466-5144-4546-b121-de18dcec541e'] !== undefined && (
        <DescriptionQuestion
          response={responses['a6780466-5144-4546-b121-de18dcec541e']}
          text="What chemicals or materials make your allergy symptoms worse?"
        />
      )}
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_ALLERGY_ISSUES_IN_RESPIRATOR)}
        response={responses.allergiesMakesRespiratorUseUnsafe}
        text="Do you think your allergies will make it hard for you to use a respirator safely?"
      />
    </QuestionSection>
  );
}
