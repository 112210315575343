import { MedicalSurveillanceEvaluationFlag, RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { Frequency, YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { DescriptionQuestion } from './DescriptionQuestion';
import { EnumQuestion } from './EnumQuestion';

import { QuestionSection } from './QuestionSection';
import { SeverityQuestion } from './SeverityQuestion';
import { YesNoQuestion } from './YesNoQuestion';

export function AsthmaBlock({
  gcdmseFlags,
  responses,
  rmeFlags,
}: {
  gcdmseFlags: MedicalSurveillanceEvaluationFlag[];
  responses: HealthHistoryStageResponses;
  rmeFlags: RespiratorMedicalEvaluationFlag[];
}) {
  return (
    <QuestionSection highlight={responses.asthma === YesNo.YES} title="Asthma">
      <YesNoQuestion
        response={responses.asthma}
        text="Have you ever had asthma?"
      />
      <YesNoQuestion
        response={responses.asthmaCurrent}
        text="Do you still have asthma?"
      />
      {responses['2f16d617-d5ef-4b8b-a23b-bb2503f9f986'] !== undefined && (
        <div>When did you develop asthma? <span className="font-bold ml-2">{responses['2f16d617-d5ef-4b8b-a23b-bb2503f9f986']}</span></div>
      )}
      {responses['efcf5a21-797a-41e8-a519-c3dbe4136d68'] !== undefined && (
        <div>When did you stop having asthma? <span className="font-bold ml-2">{responses['efcf5a21-797a-41e8-a519-c3dbe4136d68']}</span></div>
      )}
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.ASTHMA_SX_DURING_ADL)}
        response={responses.asthmaSxDuringAdl}
        text="Does your asthma bother you when you do everyday things like chores, exercising, or walking up stairs?"
      />
      {responses['aec7dc48-63bd-4000-aa9b-6126d3a22563'] !== undefined && (
        <YesNoQuestion
          response={responses['aec7dc48-63bd-4000-aa9b-6126d3a22563']}
          text="Does your asthma only happen when you exercise?"
        />
      )}
      {responses['05723079-f64f-4f66-90c2-17d53329c6d3'] !== undefined && (
        <YesNoQuestion
          response={responses['05723079-f64f-4f66-90c2-17d53329c6d3']}
          text="In the past 6 months, have you had wheezing, trouble breathing, or coughing fits that weren't because of a cold or flu?"
        />
      )}
      <YesNoQuestion
        response={responses.asthmaMedications}
        text="Do you take any rescue medicines like albuterol when you have symptoms?"
      />
      {(responses['5fb58b44-53fb-4db7-9a39-571ee420f883'] !== YesNo.YES
          && responses.asthmaMedications === YesNo.YES) && (
        <YesNoQuestion
          highlight={
            gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.POORLY_CONTROLLED_ASTHMA)
            || rmeFlags.includes(RespiratorMedicalEvaluationFlag.POORLY_CONTROLLED_ASTHMA)
          }
          response={responses.rescueInhalerUseAtLeast3PerWeek}
          text="Do you use your rescue inhaler 3 or more times per week?"
        />
      )}
      {(responses['7e284cf1-b735-4156-844a-fd84f5f7234c'] !== undefined
          && (
            responses.asthmaSxDuringAdl === YesNo.YES
            || responses['aec7dc48-63bd-4000-aa9b-6126d3a22563'] === YesNo.NO
            || responses['05723079-f64f-4f66-90c2-17d53329c6d3'] === YesNo.YES
          )
          && responses.asthmaMedications === YesNo.YES) && (
        <YesNoQuestion
          highlight={gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.RESCUE_INHALER_NEEDED_MORE_AT_WORK)}
          response={responses['7e284cf1-b735-4156-844a-fd84f5f7234c']}
          text="Do you have to use your rescue inhaler more when you are at work?"
        />
      )}
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.ASTHMA_RESCUE_MEDS_INACCESSIBLE_AT_WORK)}
        response={responses.asthmaMedicationsAccessibleAtWork}
        text="When you're at work, can you get to your medicines easily and take them whenever you need to?"
      />
      {responses['9f1a5610-22cb-4440-bbb0-7becd2fc75d1'] !== undefined && (
        <EnumQuestion
          choices={{
            [Frequency.DAILY]: 'DAILY',
            [Frequency.MONTHLY]: 'MONTHLY',
            [Frequency.RARELY]: 'RARELY',
            [Frequency.WEEKLY]: 'WEEKLY',
          }}
          highlight={gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.FREQUENT_ASTHMA_SX)}
          response={responses['9f1a5610-22cb-4440-bbb0-7becd2fc75d1']}
          text="How often do you wheeze, have trouble breathing, or cough?"
        />
      )}
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.UNSTABLE_ASTHMA)}
        response={responses.asthmaWorseLastYear}
        text="Has your asthma gotten worse in the last year?"
      />
      <SeverityQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.SEVERE_ASTHMA)}
        response={responses.asthmaSeverity}
        text="How bad do you and your doctor think your asthma is?"
      />
      {responses['3a089de7-cee6-4bcd-b7bb-24bd6fabff26'] !== undefined && (
        <YesNoQuestion
          highlight={gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.ASTHMA_SX_ONSET_P_BEGINNING_JOB)}
          response={responses['3a089de7-cee6-4bcd-b7bb-24bd6fabff26']}
          text="Did your asthma symptoms start before you began your current job?"
        />
      )}
      {responses['842b543e-57a0-47d8-ae1f-36d19aee4eb6'] !== undefined && (
        <YesNoQuestion
          highlight={gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.ASTHMA_SX_WORSE_AT_WORK)}
          response={responses['842b543e-57a0-47d8-ae1f-36d19aee4eb6']}
          text="Do your asthma symptoms get worse when you're at work or during the work week?"
        />
      )}
      {responses['5c41b0c3-5df5-4588-a80e-2b842825ffd5'] !== undefined && (
        <YesNoQuestion
          highlight={gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.ASTHMA_SX_BETTER_AWAY_FROM_WORK)}
          response={responses['5c41b0c3-5df5-4588-a80e-2b842825ffd5']}
          text="Do your asthma symptoms get better when you are off work or go on vacation?"
        />
      )}
      {responses['624c8880-5ac0-4915-8f62-ca0c559cac98'] !== undefined && (
        <YesNoQuestion
          response={responses['624c8880-5ac0-4915-8f62-ca0c559cac98']}
          text="Do you just have asthma symptoms during certain seasons?"
        />
      )}
      {responses['badec139-ab3e-4cb5-9f24-515e1baeec58'] !== undefined && (
        <YesNoQuestion
          highlight={gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.HX_JOB_CHANGE_DT_ASTHMA_SX)}
          response={responses['badec139-ab3e-4cb5-9f24-515e1baeec58']}
          text="Have you ever had to change jobs because of your asthma symptoms?"
        />
      )}
      {responses['8987d1e9-f316-4a4a-9547-8c73676f7460'] !== undefined && (
        <YesNoQuestion
          highlight={gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.ASTHMA_SX_DT_JOB_TASKS)}
          response={responses['8987d1e9-f316-4a4a-9547-8c73676f7460']}
          text="Are there specific tasks at your job that make your asthma symptoms worse?"
        />
      )}
      {responses['465b9e54-3e3e-4dfc-b215-ded62b564de7'] !== undefined && (
        <>
          <div>Which of your job tasks make your asthma symptoms worse?</div>
          <ul className="list-disc ml-6">
            {responses['465b9e54-3e3e-4dfc-b215-ded62b564de7'].map((task, i) => (
              <li key={i}>{task}</li>
            ))}
          </ul>
        </>
      )}
      {responses['3659c8b8-c3b7-4918-9ce6-d3d4e46b59ce'] !== undefined && (
        <YesNoQuestion
          highlight={gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.ASTHMA_SX_DT_JOB_MATERIALS)}
          response={responses['3659c8b8-c3b7-4918-9ce6-d3d4e46b59ce']}
          text="Are there certain chemicals or materials you work with that make your asthma symptoms worse?"
        />
      )}
      {responses['f2396d7e-abe8-40c9-a624-6f543dcb3e47'] !== undefined && (
        <DescriptionQuestion
          response={responses['f2396d7e-abe8-40c9-a624-6f543dcb3e47']}
          text="What chemicals or materials make your asthma symptoms worse?"
        />
      )}
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.HX_ASTHMA_ISSUES_IN_RESPIRATOR)}
        response={responses.asthmaPriorRespiratorUseNotOk}
        text="Has your asthma ever made it difficult for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_ASTHMA_ISSUES_IN_RESPIRATOR)}
        response={responses.asthmaFutureRespiratorUseExpectedNotOk}
        text="Do you think your asthma will make it hard for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_ASTHMA)}
        response={responses.asthmaWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your asthma?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.ASTHMA_AGGRAVATORS_AT_WORK)}
        response={responses.asthmaAggravatorsAtWork}
        text="Will you be working with anything at your job that triggers your asthma or makes your asthma worse?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_ASTHMA)}
        response={responses.asthmaHospitalStayLast2Years}
        text="In the last two (2) years, have you had to stay in the hospital because of your asthma?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.HX_ASTHMA_IMPACTING_WORK)}
        response={responses.asthmaImpactedWorkLast2Years}
        text="In the last two (2) years, have you had to leave work or stop doing a task early because of your asthma?"
      />
    </QuestionSection>
  );
}
