import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { DescriptionQuestion } from './DescriptionQuestion';
import { PriorRespiratorUseProblemQuestion } from './PriorRespiratorUseProblemQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function SkinProblemsBlock({
  responses,
  rmeFlags,
}: {
  responses: HealthHistoryStageResponses;
  rmeFlags: RespiratorMedicalEvaluationFlag[];
}) {
  if (responses['b19cf64e-7a1a-48f5-aa98-a757f36ade67'] === undefined
      && responses.priorRespiratorUseSkinProblems === null) {
    return null;
  }

  return (
    <QuestionSection
      highlight={
        responses['b19cf64e-7a1a-48f5-aa98-a757f36ade67'] === YesNo.YES
        || responses['0435a383-4184-48ac-8339-ea50cdf1b143'] === YesNo.YES
        || responses.priorRespiratorUseSkinProblems === YesNo.YES
      }
      title="Skin Problems"
    >
      {responses['b19cf64e-7a1a-48f5-aa98-a757f36ade67'] !== undefined && (
        <YesNoQuestion
          response={responses['b19cf64e-7a1a-48f5-aa98-a757f36ade67']}
          text="Have you ever had eczema or a skin allergy?"
        />
      )}
      {responses['bc654f1a-1348-417a-8176-70b3ba7eb9bd'] !== undefined && (
        <YesNoQuestion
          response={responses['bc654f1a-1348-417a-8176-70b3ba7eb9bd']}
          text="Do you still have eczema or a skin allergy?"
        />
      )}
      {responses['0435a383-4184-48ac-8339-ea50cdf1b143'] !== undefined && (
        <YesNoQuestion
          response={responses['0435a383-4184-48ac-8339-ea50cdf1b143']}
          text="In the past month, have you had any hives, itchy skin, or rashes?"
        />
      )}
      {responses['0db2f144-8a8d-4415-a77f-0d1f3f7ed7d1'] !== undefined && (
        <YesNoQuestion
          response={responses['0db2f144-8a8d-4415-a77f-0d1f3f7ed7d1']}
          text="Is your recent rash or itching caused by something other than allergies?"
        />
      )}
      {responses['4b1d5c65-dd5f-4b8d-bbce-2c78861cfb91'] !== undefined && (
        <div>What year did your skin problems start? <span className="font-bold ml-2">{responses['4b1d5c65-dd5f-4b8d-bbce-2c78861cfb91']}</span></div>
      )}
      {responses['cff575e0-098d-45a4-93a0-193d0c35a2ac'] !== undefined && (
        <div>What year did your eczema resolve? <span className="font-bold ml-2">{responses['cff575e0-098d-45a4-93a0-193d0c35a2ac']}</span></div>
      )}
      {responses['84b03557-073b-4576-9d21-6ef87d2539f0'] !== undefined && (
        <YesNoQuestion
          highlight={responses['84b03557-073b-4576-9d21-6ef87d2539f0'] === YesNo.NO}
          response={responses['84b03557-073b-4576-9d21-6ef87d2539f0']}
          text="Did your skin problems start before you began your current job?"
        />
      )}
      {responses['541f8f12-0928-437e-a3f6-2ffc6bb5f829'] !== undefined && (
        <YesNoQuestion
          highlight={responses['541f8f12-0928-437e-a3f6-2ffc6bb5f829'] === YesNo.YES}
          response={responses['541f8f12-0928-437e-a3f6-2ffc6bb5f829']}
          text="Do your skin problems get worse when you're at work or during the work week?"
        />
      )}
      {responses['cb8f3c07-7fda-4d5d-9291-8c7d86b26cfd'] !== undefined && (
        <YesNoQuestion
          highlight={responses['cb8f3c07-7fda-4d5d-9291-8c7d86b26cfd'] === YesNo.YES}
          response={responses['cb8f3c07-7fda-4d5d-9291-8c7d86b26cfd']}
          text="Do your skin problems get better when you are off work or go on vacation?"
        />
      )}
      {responses['353b3f56-246e-4829-8c40-5e6c651ef2b5'] !== undefined && (
        <YesNoQuestion
          response={responses['353b3f56-246e-4829-8c40-5e6c651ef2b5']}
          text="Do you just have skin problems during certain seasons?"
        />
      )}
      {responses['dbf154c4-1dbb-4f50-b5bb-68081566aef8'] !== undefined && (
        <YesNoQuestion
          highlight={responses['dbf154c4-1dbb-4f50-b5bb-68081566aef8'] === YesNo.YES}
          response={responses['dbf154c4-1dbb-4f50-b5bb-68081566aef8']}
          text="Have you ever had to change jobs because of your skin problems?"
        />
      )}
      {responses['eeb3aa89-d12c-4fce-bb8c-e95c5e17602d'] !== undefined && (
        <YesNoQuestion
          highlight={responses['eeb3aa89-d12c-4fce-bb8c-e95c5e17602d'] === YesNo.YES}
          response={responses['eeb3aa89-d12c-4fce-bb8c-e95c5e17602d']}
          text="Are there specific tasks at your job that make your skin problems worse?"
        />
      )}
      {responses['ac06d804-de22-4bcc-a731-047f153eecb8'] !== undefined && (
        <>
          <div>Which of your job tasks make your skin problems worse?</div>
          <ul className="list-disc ml-6">
            {responses['ac06d804-de22-4bcc-a731-047f153eecb8'].map((task, i) => (
              <li key={i}>{task}</li>
            ))}
          </ul>
        </>
      )}
      {responses['a3ffc7ec-3ee5-4033-b3a9-0170052038c7'] !== undefined && (
        <YesNoQuestion
          highlight={responses['a3ffc7ec-3ee5-4033-b3a9-0170052038c7'] === YesNo.YES}
          response={responses['a3ffc7ec-3ee5-4033-b3a9-0170052038c7']}
          text="Are there certain chemicals or materials you work with that make your skin problems worse?"
        />
      )}
      {responses['de5e62ca-9a32-4f5a-853b-93dfa63d64c0'] !== undefined && (
        <DescriptionQuestion
          response={responses['de5e62ca-9a32-4f5a-853b-93dfa63d64c0']}
          text="What chemicals or materials make your skin problems worse?"
        />
      )}
      <YesNoQuestion
        response={responses.priorRespiratorUseSkinProblems}
        text="Has using a respirator ever caused you skin rashes or allergies?"
      />
      <YesNoQuestion
        response={responses.priorRespiratorUseSkinProblemsCurrent}
        text="Do you still get a rash when you use a respirator?"
      />
      <PriorRespiratorUseProblemQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_DERMAL_PROBLEM_AGGRAVATORS_AT_WORK)}
        response={responses.priorRespiratorUseSkinProblemsCause}
        text="What was the cause of your rash or allergy?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.DERMAL_PROBLEM_DT_RESPIRATOR_S_ALTERNATIVE)}
        response={responses.priorRespiratorUseSkinProblemsAbleToUseAlternateRespirator}
        text="In your current job, are you able to use a style of respirator that does not cause you any skin problems?"
      />
      <YesNoQuestion
        response={responses.priorRespiratorUseSkinProblemsAggravatorsAtWork}
        text="Will you still be working around the thing that causes your rash or allergy?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_DERMAL_PROBLEM_AGGRAVATORS_AT_WORK_PPE_UNAVAILABLE)}
        response={responses.priorRespiratorUseSkinProblemsCanUsePpe}
        text="At your job, are you able to prevent your skin rashes by wearing protective gear like gloves or coveralls?"
      />
    </QuestionSection>
  );
}
