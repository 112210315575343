import { MedicalSurveillanceEvaluationFlag, RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { SeverityQuestion } from './SeverityQuestion';
import { YesNoQuestion } from './YesNoQuestion';

export function ChronicBronchitisBlock({
  gcdmseFlags,
  responses,
  rmeFlags,
}: {
  gcdmseFlags: MedicalSurveillanceEvaluationFlag[];
  responses: HealthHistoryStageResponses;
  rmeFlags: RespiratorMedicalEvaluationFlag[];
}) {
  return (
    <QuestionSection highlight={responses.chronicBronchitis === YesNo.YES} title="Chronic Bronchitis">
      <YesNoQuestion
        response={responses.chronicBronchitis}
        text="Have you ever had chronic bronchitis?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.CHRONIC_BRONCHITIS_SX_DURING_ADL)}
        response={responses.chronicBronchitisSxDuringAdl}
        text="Does your chronic bronchitis bother you when you do everyday things like chores, exercising, or walking up stairs?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.UNSTABLE_CHRONIC_BRONCHITIS)}
        response={responses.chronicBronchitisWorseLastYear}
        text="Has your chronic bronchitis gotten worse in the last year?"
      />
      {responses['73db3109-ba38-4f1d-b236-96253249765f'] !== undefined && (
        <YesNoQuestion
          highlight={gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.COPD_SX_WORSE_AT_WORK)}
          response={responses['73db3109-ba38-4f1d-b236-96253249765f']}
          text="Are your COPD or chronic bronchitis symptoms worse when you are at work?"
        />
      )}
      <SeverityQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.SEVERE_CHRONIC_BRONCHITIS)}
        response={responses.chronicBronchitisSeverity}
        text="How bad do you and your doctor think your chronic bronchitis is?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.HX_CHRONIC_BRONCHITIS_ISSUES_IN_RESPIRATOR)}
        response={responses.chronicBronchitisPriorRespiratorUseNotOk}
        text="Has your chronic bronchitis ever made it difficult for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_CHRONIC_BRONCHITIS_ISSUES_IN_RESPIRATOR)}
        response={responses.chronicBronchitisFutureRespiratorUseExpectedNotOk}
        text="Do you think your chronic bronchitis will make it hard for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_CHRONIC_BRONCHITIS)}
        response={responses.chronicBronchitisWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your chronic bronchitis?"
      />
      <YesNoQuestion
        response={responses.chronicBronchitisMedications}
        text="Do you take any rescue medicines like albuterol when you have symptoms?"
      />
      {(responses['5fb58b44-53fb-4db7-9a39-571ee420f883'] !== YesNo.YES
          && responses.asthmaMedications !== YesNo.YES
          && responses['06249dc2-9b9b-4e4f-a012-af25cfa05ae7'] !== undefined  // Hack to detect GCDMS
          && responses.chronicBronchitisMedications === YesNo.YES) && (
        <YesNoQuestion
          highlight={
            gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.POORLY_CONTROLLED_ASTHMA)
            || rmeFlags.includes(RespiratorMedicalEvaluationFlag.POORLY_CONTROLLED_ASTHMA)
          }
          response={responses.rescueInhalerUseAtLeast3PerWeek}
          text="Do you use your rescue inhaler 3 or more times per week?"
        />
      )}
      {(responses['7e284cf1-b735-4156-844a-fd84f5f7234c'] !== undefined
          && !(
            (
              responses.asthmaSxDuringAdl === YesNo.YES
              || responses['aec7dc48-63bd-4000-aa9b-6126d3a22563'] === YesNo.NO
              || responses['05723079-f64f-4f66-90c2-17d53329c6d3'] === YesNo.YES
            )
            && responses.asthmaMedications === YesNo.YES)
          ) && (
        <YesNoQuestion
          highlight={gcdmseFlags.includes(MedicalSurveillanceEvaluationFlag.RESCUE_INHALER_NEEDED_MORE_AT_WORK)}
          response={responses['7e284cf1-b735-4156-844a-fd84f5f7234c']}
          text="Do you have to use your rescue inhaler more when you are at work?"
        />
      )}
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.CHRONIC_BRONCHITIS_RESCUE_MEDS_INACCESSIBLE_AT_WORK)}
        response={responses.chronicBronchitisMedicationsAccessibleAtWork}
        text="When you're at work, can you get to your medicines easily and take them whenever you need to?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_CHRONIC_BRONCHITIS)}
        response={responses.chronicBronchitisHospitalStayLast2Years}
        text="In the last two (2) years, have you had to stay in the hospital because of your chronic bronchitis?"
      />
      <YesNoQuestion
        highlight={rmeFlags.includes(RespiratorMedicalEvaluationFlag.HX_CHRONIC_BRONCHITIS_IMPACTING_WORK)}
        response={responses.chronicBronchitisImpactedWorkLast2Years}
        text="In the last two (2) years, have you had to leave work or stop doing a task early because of your chronic bronchitis?"
      />
    </QuestionSection>
  );
}
