import { WithAuthenticatorProps, withAuthenticator } from '@aws-amplify/ui-react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import * as Sentry from '@sentry/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { differenceInYears } from 'date-fns/differenceInYears';
import { format } from 'date-fns/format';
import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { LoadingIcon } from '../components/LoadingIcon';
import { JobRoleSummary } from '../components/JobRoleSummary';
import { RespiratorMedicalEvaluationFlag, mseFlagText, rmeFlagText } from '../lib/flags';
import { ArrhythmiaBlock } from '../components/ArrhythmiaBlock';
import { SmokingHistoryBlock } from '../components/SmokingHistoryBlock';
import { SeizuresBlock } from '../components/SeizuresBlock';
import { DiabetesBlock } from '../components/DiabetesBlock';
import { AllergiesBlock } from '../components/AllergiesBlock';
import { ClaustrophobiaBlock } from '../components/ClaustrophobiaBlock';
import { HyposmiaBlock } from '../components/HyposmiaBlock';
import { AsbestosisBlock } from '../components/AsbestosisBlock';
import { AsthmaBlock } from '../components/AsthmaBlock';
import { ChronicBronchitisBlock } from '../components/ChronicBronchitisBlock';
import { EmphysemaBlock } from '../components/EmphysemaBlock';
import { PneumoniaBlock } from '../components/PneumoniaBlock';
import { TuberculosisBlock } from '../components/TuberculosisBlock';
import { SilicosisBlock } from '../components/SilicosisBlock';
import { PneumothoraxBlock } from '../components/PneumothoraxBlock';
import { LungCancerBlock } from '../components/LungCancerBlock';
import { BrokenRibsBlock } from '../components/BrokenRibsBlock';
import { SobBlock } from '../components/SobBlock';
import { CoughBlock } from '../components/CoughBlock';
import { WheezeBlock } from '../components/WheezeBlock';
import { ChestPainBlock } from '../components/ChestPainBlock';
import { PriorRespiratorUseBlock } from '../components/PriorRespiratorUseBlock';
import { PriorRespiratorUseEyeIrritationBlock } from '../components/PriorRespiratorUseEyeIrritationBlock';
import { SkinProblemsBlock } from '../components/SkinProblemsBlock';
import { PriorRespiratorUseAnxietyBlock } from '../components/PriorRespiratorUseAnxietyBlock';
import { PriorRespiratorUseFatigueBlock } from '../components/PriorRespiratorUseFatigueBlock';
import { PriorRespiratorUseOtherProblemsBlock } from '../components/PriorRespiratorUseOtherProblemsBlock';
import { OtherChestInjuriesSurgeriesBlock } from '../components/OtherChestInjuriesSurgeriesBlock';
import { OtherLungProblemsBlock } from '../components/OtherLungProblemsBlock';
import { OtherLungSymptomsBlock } from '../components/OtherLungSymptomsBlock';
import { HeartMurmurBlock } from '../components/HeartMurmurBlock';
import { HeartAttackBlock } from '../components/HeartAttackBlock';
import { StrokeBlock } from '../components/StrokeBlock';
import { AnginaBlock } from '../components/AnginaBlock';
import { HeartFailureBlock } from '../components/HeartFailureBlock';
import { HypertensionBlock } from '../components/HypertensionBlock';
import { HeartburnBlock } from '../components/HeartburnBlock';
import { OtherHeartProblemsBlock } from '../components/OtherHeartProblemsBlock';
import { OtherHeartSymptomsBlock } from '../components/OtherHeartSymptomsBlock';
import { MedicationsBlock } from '../components/MedicationsBlock';
import { VisionLossBlock } from '../components/VisionLossBlock';
import { VisionCorrectionBlock } from '../components/VisionCorrectionBlock';
import { ColorBlindnessBlock } from '../components/ColorBlindnessBlock';
import { OtherVisionProblemsBlock } from '../components/OtherVisionProblems';
import { HearingLossBlock } from '../components/HearingLossBlock';
import { EarInjuriesBlock } from '../components/EarInjuriesBlock';
import { HearingAidsBlock } from '../components/HearingAidsBlock';
import { OtherHearingProblemsBlock } from '../components/OtherHearingProblemsBlock';
import { BackProblemsBlock } from '../components/BackProblemsBlock';
import { WeaknessBlock } from '../components/WeaknessBlock';
import { RangeOfMotionBlock } from '../components/RangeOfMotionBlock';
import { OtherMusculoskeletalProblemsBlock } from '../components/OtherMusculoskeletalProblemsBlock';
import { SpeakToProviderBlock } from '../components/SpeakToProviderBlock';
import { InlineRadioField } from '../components/InlineRadioField';
import { StackedCheckboxField } from '../components/StackedCheckboxField';
import { Button } from '../components/Button';
import { TextAreaField } from '../components/TextAreaField';
import { ImplantableCardiacDevicesBlock } from '../components/ImplantableCardiacDevicesBlock';
import {
  Frequency,
  MedicalSurveillanceEvaluationStatus,
  RespiratorMedicalEvaluationStatus,
  RespiratorType,
  RespiratorUsageScenario,
  RespiratoryHazard,
  Risk,
  Sex,
  YesNo,
} from '../lib/general';
import { Chart, QueryApiResponse, RespiratorMedicalEvaluationReview } from '../types/general';
import { YesNoQuestion } from '../components/YesNoQuestion';

const respiratorTypeLabels = {
  [RespiratorType.AIR_PURIFYING_DISPOSABLE]: 'Disposable Respirator (N95, P100, etc.)',
  [RespiratorType.AIR_PURIFYING_REUSABLE_FULL_FACE]: 'Full-Face Air Purifying (APR)',
  [RespiratorType.AIR_PURIFYING_REUSABLE_PARTIAL_FACE]: 'Partial-Face Air Purifying (APR)',
  [RespiratorType.POWERED_AIR_PURIFYING_FULL_FACE]: 'Full-Face Powered Air Purifying (PAPR)',
  [RespiratorType.POWERED_AIR_PURIFYING_PARTIAL_FACE]: 'Partial-Face Powered Air Purifying (PAPR)',
  [RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE]: 'Full-Face Supplied Air (SAR)',
  [RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE]: 'Partial-Face Supplied Air (SAR)',
  [RespiratorType.SUPPLIED_AIR_SCBA]: 'Self-Contained Breathing Apparatus (SCBA)',
};

const usageScenarioLabels = {
  [RespiratorUsageScenario.ESCAPE]: 'Emergency Escape',
  [RespiratorUsageScenario.FIREFIGHTING]: 'Firefighting',
  [RespiratorUsageScenario.RESCUE]: 'Rescue Team (Rescue Role)',
  [RespiratorUsageScenario.RESCUE_OVERSIGHT]: 'Rescue Team (Oversight Role)',
  [RespiratorUsageScenario.ROUTINE]: 'Routine Use',
  [RespiratorUsageScenario.SITUATIONAL]: 'Situational Use',
};

const respiratoryHazardLabels = {
  [RespiratoryHazard.CHEMICALS]: 'Chemicals',
  [RespiratoryHazard.DUST]: 'Dust',
  [RespiratoryHazard.FUMES]: 'Fumes',
  [RespiratoryHazard.INFECTIOUS_AGENTS]: 'Infectious Agents',
  [RespiratoryHazard.OXYGEN_DEFICIENT]: 'Oxygen-Deficient',
  [RespiratoryHazard.OXYGEN_ENRICHED]: 'Oxygen-Enriched',
  [RespiratoryHazard.SENSITIZERS]: 'Sensitizers',
  [RespiratoryHazard.SMOKE]: 'Smoke',
  [RespiratoryHazard.VAPORS]: 'Vapors',
};

type ChartResponse = {
  chart: Chart;
};

export function ChartPageInner({ signOut }: WithAuthenticatorProps) {
  const [chart, setChart] = useState<Chart | undefined>();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [status, setStatus] = useState<RespiratorMedicalEvaluationStatus | null>(null);
  const [approvedTypes, setApprovedTypes] = useState<RespiratorType[]>([]);
  const [chartComments, setChartComments] = useState('');
  const [publicComments, setPublicComments] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [reviews, setReviews] = useState<RespiratorMedicalEvaluationReview[] | null>(null);

  const questionnaire = getReviewableQuestionnaire(chart);
  const respiratorMedicalEvaluation = questionnaire?.respiratorMedicalEvaluation ?? null;

  useEffect(() => {
    async function fetchReviews() {
      if (respiratorMedicalEvaluation !== null) {
        const { accessToken } = (await fetchAuthSession()).tokens ?? {};

        if (accessToken === undefined) {
          return;
        }

        try {
          const result = await fetch(
            `${import.meta.env.VITE_API_BASE_URL}/respirator-medical-evaluation-reviews?respiratorMedicalEvaluationId=${respiratorMedicalEvaluation.id}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken.toString()}`,
              },
            },
          );

          if (result.status !== 200) {
            throw new Error('Failed fetch.');
          }
  
          const payload = await result.json() as QueryApiResponse<RespiratorMedicalEvaluationReview>;

          setReviews(payload.data);
        } catch (err) {
          Sentry.captureException(err);
        }
      }
    }

    void fetchReviews();
  }, [respiratorMedicalEvaluation]);

  useEffect(() => {
    async function fetchChart() {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};

      if (accessToken === undefined) {
        setLoading(false);
        return;
      }

      try {
        const result = await fetch(
          `${import.meta.env.VITE_API_BASE_URL}/charts/${params.id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken.toString()}`,
            },
          },
        );

        if (result.status !== 200) {
          throw new Error('Failed fetch.');
        }

        const payload = await result.json() as ChartResponse;

        setChart(payload.chart);
        setLoading(false);
      } catch (err) {
        Sentry.captureException(err);
        setLoading(false);
      }
    }

    void fetchChart();
  }, [params.id]);

  useEffect(() => {
    if (respiratorMedicalEvaluation === null) {
      return;
    }

    if (status === RespiratorMedicalEvaluationStatus.APPROVED) {
      setApprovedTypes(respiratorMedicalEvaluation.respiratorTypeUsages.map((rtu) => rtu.respiratorType));
    } else if (status === RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW) {
      setApprovedTypes([]);
      setPublicComments('');
    } else if (status === RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED
        || status === RespiratorMedicalEvaluationStatus.DEFERRED) {
      setApprovedTypes([]);
    }
  }, [respiratorMedicalEvaluation, status]);

  if (chart === undefined) {
    if (loading) {
      return <LoadingIcon />;
    } else {
      return <div className="text-red-500">Error.</div>;
    }
  }

  if (questionnaire === null) {
    return <div className="text-red-500">Error: nothing to review.</div>
  }

  const { dateOfBirth, firstName, lastName, middleName, sex } = chart.demographics;

  const responses = questionnaire.riskAssessment.context.responses;
  const riskAssessment = questionnaire.riskAssessment;

  // TODO | This goes away once standalone MSQ
  if (respiratorMedicalEvaluation === null
      || riskAssessment.respiratorMedicalEvaluation === undefined) {
    throw new Error();
  }

  const gcdmseFlags = riskAssessment.groundCannabisDustMedicalSurveillance?.flags ?? [];
  const rmeFlags = riskAssessment.respiratorMedicalEvaluation.flags;

  const rmeDecisionOptions = [];

  rmeDecisionOptions.push({
    label: 'Approved',
    value: RespiratorMedicalEvaluationStatus.APPROVED,
  });

  if (respiratorMedicalEvaluation.respiratorTypeUsages.length > 1) {
    rmeDecisionOptions.push({
      label: 'Partially Approved',
      value: RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED,
    });
  }

  if (respiratorMedicalEvaluation.status !== RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW) {
    rmeDecisionOptions.push({
      label: 'Interview Needed',
      value: RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW,
    });
  }

  rmeDecisionOptions.push({
    label: 'Deferred',
    value: RespiratorMedicalEvaluationStatus.DEFERRED,
  });

  let isValid = true;

  if (status === null
      || chartComments.length === 0
      || (
        status === RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED
        && (
          approvedTypes.length === 0
          || approvedTypes.length === respiratorMedicalEvaluation.respiratorTypeUsages.length
        )
      )
  ) {
    isValid = false;
  }

  async function trySubmit() {
    if (respiratorMedicalEvaluation === null || !isValid) {
      return;
    }

    const { accessToken } = (await fetchAuthSession()).tokens ?? {};

    if (accessToken === undefined) {
      return;
    }

    setError(false);
    setSubmitting(true);

    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/respirator-medical-evaluation-reviews`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.toString()}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            approvedRespiratorTypes: status === RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED
              ? approvedTypes
              : [],
            chartComments,
            evaluation: {
              id: respiratorMedicalEvaluation.id,
            },
            publicComments,
            status,
          }),
        },
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error();  // Unsuccessful
      }

      setSubmitting(false);
      navigate('/');
    } catch (err) {
      Sentry.captureException(err);
      setError(true);
      setSubmitting(false);
    }
  }

  return (
    <div className="p-8">
      <div className="mx-auto max-w-7xl">
        <div className="flex flex-row justify-between">
          <div>
            <Link className="text-[#3ac8aa] font-bold text-lg" to="/">&laquo; Back to Home</Link>
          </div>
          <button
            type="button"
            className="rounded-md bg-[#3ac8aa] px-2.5 py-1.5 text-sm font-semibold text-teal-950 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-500"
            onClick={signOut}
          >
            Sign Out
          </button>
        </div>
        <h1 className="font-display font-bold text-2xl mt-8" style={{
          background:  'linear-gradient(to bottom, #9238ff, #e501ff)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}>{`${lastName}, ${firstName} ${middleName}`.toUpperCase()}</h1>
        <div className="text-white font-bold">
          {sex === Sex.MALE ? 'Male' : 'Female'} &bull;{' '}
          {differenceInYears(new Date(), dateOfBirth)} years old &bull;{' '}
          {`${Math.floor(responses.height / 12)}'${responses.height % 12}"`} &bull;{' '}
          {responses.weight} lbs. &bull;{' '}
          <span className={rmeFlags.includes(RespiratorMedicalEvaluationFlag.HIGH_BMI) ? 'bg-orange-300 text-black': ''}>BMI {Math.round((responses.weight / 2.20462) / Math.pow(responses.height / 39.3701, 2) * 10) / 10}</span>
        </div>
        <div className="text-white font-bold">
          Phone: {chart.contactInfo.phoneNumber.substring(0, 3)}-{chart.contactInfo.phoneNumber.substring(3, 6)}-{chart.contactInfo.phoneNumber.substring(6)}
        </div>
        <div className="mt-4 text-white text-xl font-bold">Risk Assessment</div>
        <div className="rounded-md mt-2 p-6 bg-gray-50 flex flex-col gap-y-4">
          <div>
            <div className="font-semibold">Respirator Medical Evaluation</div>
            <div>
              Risk:{' '}
              <span className="font-bold text-xl">
                {riskAssessment.respiratorMedicalEvaluation.risk === Risk.LOW
                  ? <span className="text-green-500">LOW</span>
                  : riskAssessment.respiratorMedicalEvaluation.risk === Risk.MEDIUM
                    ? <span className="text-orange-500">MEDIUM</span>
                    : <span className="text-red-500">HIGH</span>
                }
              </span>
              <ul className="list-disc text-sm ml-4">
                {rmeFlags.map((rmeFlag) => (
                  <li key={rmeFlag}>{rmeFlagText[rmeFlag]}</li>
                ))}
              </ul>
            </div>
          </div>
          {riskAssessment.groundCannabisDustMedicalSurveillance !== undefined && (
            <div>
              <div className="font-semibold">Ground Cannabis Dust Medical Surveillance</div>
              <div>
                Risk:{' '}
                <span className="font-bold text-xl">
                  {riskAssessment.groundCannabisDustMedicalSurveillance.risk === Risk.LOW
                    ? <span className="text-green-500">LOW</span>
                    : riskAssessment.groundCannabisDustMedicalSurveillance.risk === Risk.MEDIUM
                      ? <span className="text-orange-500">MEDIUM</span>
                      : <span className="text-red-500">HIGH</span>
                  }
                </span>
                <ul className="list-disc text-sm ml-4">
                  {gcdmseFlags.map((gcdmseFlag) => (
                    <li key={gcdmseFlag}>{mseFlagText[gcdmseFlag]}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="mt-4 text-white text-xl font-bold">Job Role & Respirators</div>
        <div className="rounded-md bg-gray-50 mt-2 p-6">
          <JobRoleSummary
            flags={rmeFlags}
            jobRole={questionnaire.jobRole}
            respiratorTypeUsages={respiratorMedicalEvaluation.respiratorTypeUsages}
          />
        </div>
        {riskAssessment.groundCannabisDustMedicalSurveillance !== undefined && (
          <>
            <div className="mt-4 text-white text-xl font-bold">Cannabis Exposure</div>
            <div className="rounded-md bg-gray-50 mt-2 p-2">
              <div className="p-4">
                <div className="text-lg font-bold mb-2 underline">Personal Use</div>
                <div>Smokes: <span className="font-bold ml-2">{cannabisUseFrequencyDisplay(responses['3b6fe711-d74f-4c0b-a3f9-227d82d366ad'], responses['a11165a1-a535-4021-a154-eca876b0f6ad'])}</span></div>
                <div>Vapes: <span className="font-bold ml-2">{cannabisUseFrequencyDisplay(responses['124891ca-0994-454e-a97c-6570552aac77'], responses['d2bf6729-2201-47da-9dcf-c7de6da01e6c'])}</span></div>
                <div>Edibles: <span className="font-bold ml-2">{cannabisUseFrequencyDisplay(responses['70442a66-96ff-4551-acb8-b2925e21a1fd'], responses['c5908ae2-b3cb-42d4-9ced-db39c66f9a40'])}</span></div>
                <div>Topical: <span className="font-bold ml-2">{cannabisUseFrequencyDisplay(responses['303d2abd-ca14-416a-a659-b7e13e96f28e'], responses['347dd1ec-d257-4844-8461-256d7dfc94bd'])}</span></div>
              </div>
              <div className="p-4">
                <div className="text-lg font-bold mb-2 underline">At Home</div>
                <YesNoQuestion
                  response={responses['979f7938-b656-4659-95b1-d35ff63c3848']}
                  text="Handles Raw Cannabis:"
                />
                <YesNoQuestion
                  response={responses['cb9ee233-aa97-47b2-953f-c778d9d82dc1']}
                  text="Grows Cannabis:"
                />
              </div>
            </div>
          </>
        )}
        <div className="mt-4 text-white text-xl font-bold">Questionnaire</div>
        <div className="rounded-md mt-2 p-2 bg-gray-50 flex flex-col gap-2 items-stretch">
          <PriorRespiratorUseBlock flags={rmeFlags} responses={responses} />
          <PriorRespiratorUseEyeIrritationBlock flags={rmeFlags} responses={responses} />
          <SkinProblemsBlock responses={responses} rmeFlags={rmeFlags} />
          <PriorRespiratorUseAnxietyBlock flags={rmeFlags} responses={responses} />
          <PriorRespiratorUseFatigueBlock flags={rmeFlags} responses={responses} />
          <PriorRespiratorUseOtherProblemsBlock flags={rmeFlags} responses={responses} />
          <SmokingHistoryBlock flags={rmeFlags} responses={responses} />
          <SeizuresBlock flags={rmeFlags} responses={responses} />
          <DiabetesBlock flags={rmeFlags} responses={responses} />
          <AllergiesBlock gcdmseFlags={gcdmseFlags} responses={responses} rmeFlags={rmeFlags} />
          <ClaustrophobiaBlock flags={rmeFlags} responses={responses} />
          <HyposmiaBlock flags={rmeFlags} responses={responses} />
          <AsbestosisBlock flags={rmeFlags} responses={responses} />
          <AsthmaBlock gcdmseFlags={gcdmseFlags} responses={responses} rmeFlags={rmeFlags} />
          <ChronicBronchitisBlock gcdmseFlags={gcdmseFlags} responses={responses} rmeFlags={rmeFlags} />
          <EmphysemaBlock flags={rmeFlags} responses={responses} />
          <PneumoniaBlock flags={rmeFlags} responses={responses} />
          <TuberculosisBlock flags={rmeFlags} responses={responses} />
          <SilicosisBlock flags={rmeFlags} responses={responses} />
          <PneumothoraxBlock flags={rmeFlags} responses={responses} />
          <LungCancerBlock flags={rmeFlags} responses={responses} />
          <BrokenRibsBlock flags={rmeFlags} responses={responses} />
          <SobBlock gcdmseFlags={gcdmseFlags} responses={responses} rmeFlags={rmeFlags} />
          <CoughBlock flags={rmeFlags} responses={responses} />
          <WheezeBlock flags={rmeFlags} responses={responses} />
          <ChestPainBlock flags={rmeFlags} responses={responses} />
          <OtherChestInjuriesSurgeriesBlock flags={rmeFlags} responses={responses} />
          <OtherLungProblemsBlock flags={rmeFlags} responses={responses} />
          <OtherLungSymptomsBlock flags={rmeFlags} responses={responses} />
          <HeartMurmurBlock flags={rmeFlags} responses={responses} />
          <HeartAttackBlock flags={rmeFlags} responses={responses} />
          <StrokeBlock flags={rmeFlags} responses={responses} />
          <AnginaBlock flags={rmeFlags} responses={responses} />
          <HeartFailureBlock flags={rmeFlags} responses={responses} />
          <ArrhythmiaBlock flags={rmeFlags} responses={responses} />
          <ImplantableCardiacDevicesBlock flags={rmeFlags} responses={responses} />
          <HypertensionBlock flags={rmeFlags} responses={responses} />
          <HeartburnBlock flags={rmeFlags} responses={responses} />
          <OtherHeartProblemsBlock flags={rmeFlags} responses={responses} />
          <OtherHeartSymptomsBlock flags={rmeFlags} responses={responses} />
          <MedicationsBlock flags={rmeFlags} responses={responses} />
          <VisionLossBlock flags={rmeFlags} responses={responses} />
          <VisionCorrectionBlock flags={rmeFlags} responses={responses} />
          <ColorBlindnessBlock flags={rmeFlags} responses={responses} />
          <OtherVisionProblemsBlock flags={rmeFlags} responses={responses} />
          <HearingLossBlock flags={rmeFlags} responses={responses} />
          <EarInjuriesBlock flags={rmeFlags} responses={responses} />
          <HearingAidsBlock flags={rmeFlags} responses={responses} />
          <OtherHearingProblemsBlock flags={rmeFlags} responses={responses} />
          <BackProblemsBlock flags={rmeFlags} responses={responses} />
          <WeaknessBlock flags={rmeFlags} responses={responses} />
          <RangeOfMotionBlock flags={rmeFlags} responses={responses} />
          <OtherMusculoskeletalProblemsBlock flags={rmeFlags} responses={responses} />
          <SpeakToProviderBlock flags={rmeFlags} responses={responses} />
        </div>
        <div className="mt-4 text-white text-xl font-bold">Review History</div>
        <div className="rounded-md mt-2 p-6 bg-gray-50 flex flex-col gap-8 items-stretch">
          {reviews === null ? (
            <div>Could not load.</div>
          ) : reviews.length === 0 ? (
            <div>No previous reviews.</div>
          ) : (
            <div className="divide-y-2 divide-gray-500">
              {reviews.map((review) => (
                <div key={review.id}>
                  <div><b>Time:</b> {format(review.creationTime, 'MMMM d yyyy h:mm:ss a')}</div>
                  <div><b>Provider:</b> {review.provider.id}</div>
                  <div><b>Decision:</b> {{
                    [RespiratorMedicalEvaluationStatus.APPROVED]: 'Approved',
                    [RespiratorMedicalEvaluationStatus.DEFERRED]: 'Deferred',
                    [RespiratorMedicalEvaluationStatus.EXPIRED_INTERVIEW]: 'Expired',
                    [RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW]: 'Interview Needed',
                    [RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED]: 'Partially Approved',
                    [RespiratorMedicalEvaluationStatus.PENDING_REVIEW]: 'Review Needed',
                  }[review.status]}</div>
                  <div><b>Internal Chart Comments:</b> {review.chartComments}</div>
                  <div><b>Public Comments:</b> {review.publicComments}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="mt-4 text-white text-xl font-bold">Decision</div>
        <div className="rounded-md mt-2 p-2 bg-gray-50 flex flex-col gap-8 items-stretch">
          <div>
            <InlineRadioField
              onChange={setStatus}
              options={rmeDecisionOptions}
              value={status}
            />
          </div>
          <div className={status === RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW || status === RespiratorMedicalEvaluationStatus.DEFERRED ? 'opacity-50' : ''}>
            <StackedCheckboxField<RespiratorType>
              onChange={(val) => {
                if (status === RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED) {
                  setApprovedTypes(val);
                }
              }}
              options={respiratorMedicalEvaluation.respiratorTypeUsages.map((typeUsage) => ({
                label: (
                  <div className="flex flex-row gap-4">
                    <div><input checked={approvedTypes.includes(typeUsage.respiratorType)} readOnly type="checkbox" /></div>
                    <div>
                      <div className="font-bold">
                        {respiratorTypeLabels[typeUsage.respiratorType]}
                      </div>
                      <div className="ml-4 flex flex-row gap-x-2">
                        {typeUsage.usageScenarios.map((usageScenario, i) => (
                          <Fragment key={usageScenario}>
                            <span>{usageScenarioLabels[usageScenario]}</span>
                            {i !== typeUsage.usageScenarios.length - 1 && (
                              <span>&bull;</span>
                            )}
                          </Fragment>
                        ))}
                      </div>
                      <div className="ml-4 flex flex-row flex-wrap gap-x-2 text-sm text-gray-500">
                        {typeUsage.respiratoryHazards.map((respiratoryHazard, i) => (
                          <Fragment key={respiratoryHazard}>
                            <span>{respiratoryHazardLabels[respiratoryHazard]}</span>
                            {i !== typeUsage.respiratoryHazards.length - 1 && (
                              <span>&bull;</span>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                ),
                value: typeUsage.respiratorType,
              }))}
              value={approvedTypes}
            />
          </div>
          <div>
            <div className="text-md font-bold mb-2">Internal Chart Comments:</div>
            <TextAreaField
              maxLength={500}
              name="comments"
              onChange={setChartComments}
              value={chartComments}
            />
          </div>
          {(status === RespiratorMedicalEvaluationStatus.APPROVED
              || status === RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED
              || status === RespiratorMedicalEvaluationStatus.DEFERRED) && (
            <div>
              <div className="text-md font-bold mb-2">Public Comments (optional):</div>
              <div className="font-bold text-orange-500">
                <ExclamationTriangleIcon className="w-5 h-5 inline-block mr-2" />
                Do NOT include any PII or PHI/HIPAA data in this field!!
              </div>
              <TextAreaField
                maxLength={500}
                name="comments"
                onChange={setPublicComments}
                value={publicComments}
              />
            </div>
          )}
          {error && (
            <div className="rounded-md bg-red-100 text-red-500 p-4 border border-red-500">Error submitting.</div>
          )}
          <Button disabled={submitting || !isValid} loading={submitting} onClick={() => {
            void trySubmit();
          }}>
            Submit Decision
          </Button>
        </div>
      </div>
    </div>
  );
}

function cannabisUseFrequencyDisplay(
  useAtAll: YesNo,
  frequency: Frequency,
) {
  return useAtAll === YesNo.NO
    ? 'NEVER'
    // @ts-expect-error Other Frequency values will not occur here
    : {
      [Frequency.MULTIPLE_DAILY]: 'MULTIPLE TIMES PER DAY',
      [Frequency.DAILY]: 'DAILY',
      [Frequency.WEEKLY]: 'WEEKLY',
      [Frequency.MONTHLY]: 'MONTHLY',
      [Frequency.RARELY]: 'RARELY',
    }[frequency];
}

function getReviewableQuestionnaire(chart: Chart | undefined): Chart['questionnaires'][number] | null {
  if (chart === undefined) {
    return null;
  }

  let reviewableQuestionnaire: Chart['questionnaires'][number] | null = null;

  for (const questionnaire of chart.questionnaires) {
    const gcdmse = questionnaire.groundCannabisDustMedicalSurveillanceEvaluation;
    const rme = questionnaire.respiratorMedicalEvaluation;

    if (
      rme.status === RespiratorMedicalEvaluationStatus.PENDING_REVIEW
      || rme.status === RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW
      || (
        gcdmse !== undefined
        && (
          gcdmse.status === MedicalSurveillanceEvaluationStatus.PENDING_REVIEW
          || gcdmse.status === MedicalSurveillanceEvaluationStatus.PENDING_INTERVIEW
        )
      )
    ) {
      if (reviewableQuestionnaire !== null) {
        throw new Error('Multiple outstanding questionnaires.');
      }

      reviewableQuestionnaire = questionnaire;
    }
  }

  return reviewableQuestionnaire;
}

export const ChartPage = withAuthenticator(ChartPageInner);
