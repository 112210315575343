import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { ArrhythmiaType, YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function ArrhythmiaBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.arrhythmia === YesNo.YES || responses.skippedHeartbeatLastTwoYears === YesNo.YES} title="Arrhythmia">
      <YesNoQuestion
        highlight={responses.arrhythmia === YesNo.YES && responses.heartMurmur === YesNo.NO && flags.includes(RespiratorMedicalEvaluationFlag.ARRHYTHMIA_C_STRUCTURAL_HEART_DISEASE)}
        response={responses.arrhythmia}
        text="Have you ever been diagnosed with an arrhythmia?"
      />
      <YesNoQuestion
        response={responses.skippedHeartbeatLastTwoYears}
        text="In the past two years, have you noticed yourself having an irregular heartbeat (heart skipping or missing a beat)?"
      />
      <YesNoQuestion
        highlight={responses.arrhythmiaOtherThanHeartMurmur === YesNo.YES && flags.includes(RespiratorMedicalEvaluationFlag.ARRHYTHMIA_C_STRUCTURAL_HEART_DISEASE)}
        response={responses.arrhythmiaOtherThanHeartMurmur}
        text={`Do you have ${responses.arrhythmia === YesNo.YES ? 'arrhythmia' : 'an irregular heartbeat'} aside from your heart murmur?`}
      />
      <div>{`What type(s) of ${responses.arrhythmia === YesNo.YES ? 'arrhythmia' : 'irregular heartbeat'} do you have? Select all that apply:`} {responses.arrhythmiaTypes !== null && responses.arrhythmiaTypes.includes(ArrhythmiaType.UNKNOWN) && <span className="font-bold ml-2 bg-orange-200">UNSURE</span>}</div>
      {responses.arrhythmiaTypes !== null && !responses.arrhythmiaTypes.includes(ArrhythmiaType.UNKNOWN) && (
        <ul className="list-disc ml-6">
          {responses.arrhythmiaTypes.includes(ArrhythmiaType.ATRIAL_FIBRILLATION_OR_FLUTTER) && (
            <li><span className={flags.includes(RespiratorMedicalEvaluationFlag.MULTIPLE_ARRHYTHMIAS) ? 'bg-orange-200': ''}>Atrial Fibrillation or Flutter</span></li>
          )}
          {responses.arrhythmiaTypes.includes(ArrhythmiaType.BUNDLE_BRANCH_BLOCK) && (
            <li><span className={flags.includes(RespiratorMedicalEvaluationFlag.MULTIPLE_ARRHYTHMIAS) ? 'bg-orange-200': ''}>Bundle-Branch Block</span></li>
          )}
          {responses.arrhythmiaTypes.includes(ArrhythmiaType.AV_BLOCK) && (
            <li><span className={flags.includes(RespiratorMedicalEvaluationFlag.MULTIPLE_ARRHYTHMIAS) ? 'bg-orange-200': ''}>AV Block</span></li>
          )}
          {responses.arrhythmiaTypes.includes(ArrhythmiaType.VENTRICULAR_TACHYCARDIA) && (
            <li><span className={flags.includes(RespiratorMedicalEvaluationFlag.MULTIPLE_ARRHYTHMIAS) ? 'bg-orange-200': ''}>Ventricular Tachycardia</span></li>
          )}
          {responses.arrhythmiaTypes.includes(ArrhythmiaType.PREMATURE_VENTRICULAR_CONTRACTIONS) && (
            <li><span className={flags.includes(RespiratorMedicalEvaluationFlag.MULTIPLE_ARRHYTHMIAS) ? 'bg-orange-200': ''}>Premature Ventricular Contractions (PVCs)</span></li>
          )}
          {responses.arrhythmiaTypes.includes(ArrhythmiaType.SUPRAVENTRICULAR_TACHYCARDIA) && (
            <li><span className={flags.includes(RespiratorMedicalEvaluationFlag.MULTIPLE_ARRHYTHMIAS) ? 'bg-orange-200': ''}>Supraventricular Tachycardia</span></li>
          )}
          {responses.arrhythmiaTypes.includes(ArrhythmiaType.LONG_QT_SYNDROME) && (
            <li><span className={flags.includes(RespiratorMedicalEvaluationFlag.MULTIPLE_ARRHYTHMIAS) || flags.includes(RespiratorMedicalEvaluationFlag.HIGH_RISK_ARRHYTHMIA) ? 'bg-orange-200': ''}>Long QT Syndrome</span></li>
          )}
          {responses.arrhythmiaTypes.includes(ArrhythmiaType.SINUS_NODE_DYSFUNCTION) && (
            <li><span className={flags.includes(RespiratorMedicalEvaluationFlag.MULTIPLE_ARRHYTHMIAS) || flags.includes(RespiratorMedicalEvaluationFlag.HIGH_RISK_ARRHYTHMIA) ? 'bg-orange-200': ''}>Sinus Node Dysfunction</span></li>
          )}
          {responses.arrhythmiaTypes.includes(ArrhythmiaType.OTHER) && (
            <li><span className={flags.includes(RespiratorMedicalEvaluationFlag.MULTIPLE_ARRHYTHMIAS) || flags.includes(RespiratorMedicalEvaluationFlag.HIGH_RISK_ARRHYTHMIA) ? 'bg-orange-200': ''}>Other</span></li>
          )}
        </ul>
      )}
      <YesNoQuestion
        highlight={responses.arrhythmiaWorkAccommodations === YesNo.YES}
        response={responses.arrhythmiaWorkAccommodations}
        text={`Do you have any, or will you be requesting any, medical restrictions or accommodations for your ${responses.arrhythmia === YesNo.YES ? 'arrhythmia' : 'irregular heartbeat'}?`}
      />
    </QuestionSection>
  );
}
